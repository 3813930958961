.logoImg {}

.warningText {
  text-align: center;
  vertical-align: middle;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  color: #000000;
}

.paperContainer {
  margin-top: 10px;
  display: 'inline-block';
}

.detailsBoxContainer {
  /* padding-top: 5px;
  padding-bottom: 5px; */

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  bottom: 35px;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  padding-left: 8px;
  border-radius: 5px 5px 4px 4px;
  padding-right: 8px;
  right: 5px;
  height: 256px;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  /* Firefox */
  -webkit-animation: fadein 0.5s;
  /* Safari and Chrome */
  -o-animation: fadein 0.5s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {

  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.detailsSectionContainer {
  padding-bottom: 5px;
}

.sectionParagraph {
  padding-bottom: 5px;
}

.titleRBModal {
  /* float: left; */
  padding: 0 !important;
}

.titleRBHeader img {
  /* float: left; */
  width: 100%;
}

.titleRBHeaderContainer {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.titlePrimaryText {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  margin-left: auto;
}

.titleSecondaryText {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: auto;
}


.modalHeader {
  padding: 0 !important;
  margin: -8px !important;
  border-radius: 2px;
  font-size: 16px !important;
  line-height: 20px !important;
}

.modalHeader img {
  height: 90px;
  /* width: 460px; */
  padding-top: 22px;
  padding-bottom: 12px;
}