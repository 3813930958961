/* Copyright 2016 Rigo Investment Sagl. */

/* .body {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

} */

.headline {
  font-size: 24;
  padding-top: 16;
  margin-bottom: 12;
  font-weight: 400;
}

.tabs {
  width: 100%;
  float: 'left';
}

.tab {}

.copyright {
  font-size: 0.9em;
}

.bottombar {
  /* position: fixed;
  width: 100%;
  bottom: 0; */
  align-self: flex-end !important;
  /* flex: none !important; */
}

.maincontainer {
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.content {
  flex: 1 0 auto !important;
  height: 100%;
  margin-top: 55px;
}

.contentHomePages {
  flex: 1 0 auto !important;
  height: 100%;
  margin-top: 18px;
}

.comingSoonBlur {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* ie8 */
  padding-left: 5px;
  /* padding-right: 5px; */
}

.fix {
  padding-right: 0px !important;
  padding-left: 0px !important;
}