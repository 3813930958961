@import url(https://fonts.googleapis.com/css?family=Muli);
.elementNotification_progressBar__1tcis {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}

.elementNotification_menu__1_WIS {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    float: right;
    margin-top: -10px;
    /* padding-right: 5px; */
}

@-webkit-keyframes elementNotification_pulse__1_nNt {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@keyframes elementNotification_pulse__1_nNt {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

.elementNotification_post__ayRHE {
    margin-bottom: 12px;
    padding: 16px 16px 42px;
    border-radius: 4px;
    width: 530px;
    height: 40px;
    margin: 10px auto 20px;
    background: #E1E9EE;
}

.elementNotification_width500__2qUyD {
    width: 500px;
}

.elementNotification_width470__2wb6c {
    width: 470px;
}

.elementNotification_width110__MPS15 {
    width: 110px;
}

.elementNotification_width250__3Szml {
    width: 250px;
}

.elementNotification_line__3eJRm {
    height: 10px;
    margin: 10px 0;
}

.elementNotification_circle__1Eax3,
.elementNotification_line__3eJRm,
.elementNotification_rect__sLG25 {
    background: #fff;
    -webkit-animation: elementNotification_pulse__1_nNt .65s infinite alternate;
    animation: elementNotification_pulse__1_nNt .65s infinite alternate;
}

.elementNotification_circle__1Eax3 {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    width: 56px;
    height: 56px;
}

.elementNotification_wrapper__ccrF2 {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.elementNotification_height20__3zKNj {
    height: 20px;
}

.elementAppLoading_loadingDiv__OlHcH {
  width: 100px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.elementAppLoading_logoImg__3uiWD {
  width: 68px;
  margin-left: -5px;
  padding-bottom: 15px;
}

.elementAppLoading_divFullHeight__3-lTT {
  height:100vh;
  background-color: #F5F5F5; 
}

.elementAppLoading_warningText__2QtYY {
  text-align: center;
}

.elementAppLoading_titleRBModal__1XMbp {
  /* float: left; */
  padding: 0 !important; 
}

.elementAppLoading_titleRBHeader__3QByC img {
  /* float: left; */
  width: 100%;
}

.elementAppLoading_titleRBHeaderContainer__1f4J- {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.elementAppLoading_titlePrimaryText__21qm6 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  margin-left: auto;
}

.elementAppLoading_titleSecondaryText__19ydq {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: auto;
}


.elementAppLoading_modalHeader__FbJY7 {
  padding: 0 !important;
  margin: -8px !important;
  border-radius: 2px;
  font-size: 16px !important;
  line-height: 20px !important; 
}

.elementAppLoading_modalHeader__FbJY7 img {
  width: 100%;
}
.accountTopBarSummary_accountChipTokenETH__1F1Fa {
  float: left;
  padding-bottom: 2px;
  margin-right: 2px;
  /* width: 130px !important; */
  /* display: flex;
  justify-content: flex-end */
}

.accountTopBarSummary_accountChipTokenGGG__1T_Qx {
  padding-bottom: 2px;
  float: right;
  /* width: 130px !important; */
  /* display: flex;
  justify-content: flex-end */
}

.accountTopBarSummary_transferButton__LQ29I {
  font-weight: 600 !important;
}

.accountTopBarSummary_copyAddress__6E_LO {
  height: 16px !important;
  width: 16px !important;
}

.accountTopBarSummary_actionButtons__3e_WL {
  text-align: right;
}


.accountTopBarSummary_paperContainer__3lKra {
  padding-top: 10px;
  padding-bottom: 10px;
  display: 'inline-block';
}

.accountTopBarSummary_accountTitleContainer__37t6l {
  padding: 10px;
}

.accountTopBarSummary_accountBodyContainer__2imYO {
  padding-top: 8px;
}

.accountTopBarSummary_title__2vWNc {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
}

.accountTopBarSummary_subTitle__6d0t7 {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.accountTopBarSummary_actionIcons__dq8ah {
  margin: 4px;
}

.accountTopBarSummary_balance__3RAPL {
  font-size: 14px;
}

.accountTopBarSummary_balance__3RAPL span {
  vertical-align: middle;
  margin-left: 4px;
}

.accountTopBarSummary_actionsContainer__2epU6 {
  border-right-color: #e0e0e0;
  border-right-style: solid;
  border-right-width: 1px;
}
.elementNotificationsList_notifications__3iRa- {
    margin-top: 54px;
    /* overflow: hidden !important;
    position: absolute !important; */
    /* transition: none !important; */
    /* height: 88px !important; */
}

.elementNotificationsList_notificationsPanel__3_yUh {
    position: absolute;
    /* margin-top: 54px; */
    z-index: 1100;
    background-color: #ffffff;
    right: 0;
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    display: none;
    padding-right: 8px;
    padding-left: 4px;
    border-top-left-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 2px;
}

.elementNotificationsList_show__1VDah {
    display: inline;
}

.elementNotificationsList_noShow__2OCsC {
    display: none;
}


.elementNotificationsList_progressBar__3xBJZ {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}

.elementNotificationsList_noRecentTransactions__2wdV0 {
    height: 62px
}

@-webkit-keyframes elementNotificationsList_pulse__tTQWn {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@keyframes elementNotificationsList_pulse__tTQWn {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

.elementNotificationsList_post__1Lvrv {
    margin-bottom: 12px;
    padding: 16px 16px 42px;
    border-radius: 4px;
    width: 530px;
    height: 40px;
    margin: 10px auto 20px;
    background: #E1E9EE;
}

.elementNotificationsList_width500__3Mawi {
    width: 500px;
}

.elementNotificationsList_width470__2a8Mb {
    width: 470px;
}

.elementNotificationsList_width110__3Yr8f {
    width: 110px;
}

.elementNotificationsList_width250__19Ysc {
    width: 250px;
}

.elementNotificationsList_line__1DzBK {
    height: 10px;
    margin: 10px 0;
}

.elementNotificationsList_circle__17rxI,
.elementNotificationsList_line__1DzBK,
.elementNotificationsList_rect__CxCOO {
    background: #fff;
    -webkit-animation: elementNotificationsList_pulse__tTQWn .65s infinite alternate;
    animation: elementNotificationsList_pulse__tTQWn .65s infinite alternate;
}

.elementNotificationsList_circle__17rxI {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    width: 56px;
    height: 56px;
}

.elementNotificationsList_wrapper__2IYcg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.elementNotificationsList_height20__2YQzW {
    height: 20px;
}

.elementNotificationsList_noTransacationsMsg__2YNHK {
    text-align: center;
}
.elementTopBarAccountStatus_notifications__5pzvX {
    margin-top: 54px;
    /* overflow: hidden !important;
    position: absolute !important; */
    /* transition: none !important; */
    /* height: 88px !important; */
}

.elementTopBarAccountStatus_notificationsPanel__3ziqH {
    position: absolute;
    /* margin-top: 54px; */
    z-index: 1100;
    background-color: #ffffff;
    right: 0;
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    display: none;
    padding-right: 8px;
    padding-left: 4px;
    border-top-left-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 2px;
}

.elementTopBarAccountStatus_show__qE0Fu {
    display: inline;
}

.elementTopBarAccountStatus_noShow__30QtB {
    display: none;
}


.elementTopBarAccountStatus_progressBar__2FuXB {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}

.elementTopBarAccountStatus_noRecentTransactions__2v3mz {
    height: 62px
}

@-webkit-keyframes elementTopBarAccountStatus_pulse__3MUFJ {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

@keyframes elementTopBarAccountStatus_pulse__3MUFJ {
    0% {
        opacity: .5
    }

    100% {
        opacity: 1
    }
}

.elementTopBarAccountStatus_post__PihfA {
    margin-bottom: 12px;
    padding: 16px 16px 42px;
    border-radius: 4px;
    width: 530px;
    height: 40px;
    margin: 10px auto 20px;
    background: #E1E9EE;
}

.elementTopBarAccountStatus_width500__2OBS4 {
    width: 500px;
}

.elementTopBarAccountStatus_width470__oVPOa {
    width: 470px;
}

.elementTopBarAccountStatus_width110__2FP8W {
    width: 110px;
}

.elementTopBarAccountStatus_width250__k06mR {
    width: 250px;
}

.elementTopBarAccountStatus_line__2pBF_ {
    height: 10px;
    margin: 10px 0;
}

.elementTopBarAccountStatus_circle__ZkTeH,
.elementTopBarAccountStatus_line__2pBF_,
.elementTopBarAccountStatus_rect__3nAKb {
    background: #fff;
    -webkit-animation: elementTopBarAccountStatus_pulse__3MUFJ .65s infinite alternate;
    animation: elementTopBarAccountStatus_pulse__3MUFJ .65s infinite alternate;
}

.elementTopBarAccountStatus_circle__ZkTeH {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    width: 56px;
    height: 56px;
}

.elementTopBarAccountStatus_wrapper__1r2sa {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.elementTopBarAccountStatus_height20__17ZjU {
    height: 20px;
}

.elementTopBarAccountStatus_noTransacationsMsg__3tOOt {
    text-align: center;
}
.topMenuLinkLong_topBarButton__2a8jw {
  color: white !important;
  line-height: 32px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin-top: 5px !important;
  padding-top: 4px !important;
}

.topMenuLinkLong_topBarButtonSelected__1nPva {
  color: white !important;
  line-height: 32px !important;
  border-bottom-color: #FFFFFF !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin-top: 5px !important;
  padding-top: 4px !important;
}
/* topBarLinksMenu */


.elements_networkIconClosed__A5nwx {
  margin-right: -8px;
  margin-top: -4px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  padding-right: 8px;
}

.elements_networkIconOpen__3DQwA {
  margin-right: -8px;
  margin-top: -4px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: -4px;
  padding-right: 8px;
  background-color: #fff;
  color: #054186 !important;
}

/* leftSideDrawer */

.elements_maincontainer__PFHCb {
  height: 100%
}

.elements_leftDrawer__3Hl35 {
  height: 100% !important;
  position: relative !important;
  z-index: 1 !important;
}

.elements_containerleftDrawer__S2esg {
  position: relative !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 10px !important;
  overflow-x: hidden !important;
  width: 100% !important;
  /* min-width: 200px !important; */
}

.elements_menu__2b-Yk {
  width: 200% !important;
}

.elements_activeLink__1QFwh {
  font-weight: 900 !important;
  color: red !important;
}

a.elements_selected__1Dw39 {
  color: red !important;
}

.elements_notifications__31nNJ {}

.elements_menuItemPrimaryText__23C8K {
  font-weight: 600 !important;
  margin-bottom: -15px;
}

.elements_menuAccountType__1-zaA {
  padding: 5px 0px !important;
}

@media screen and (max-width: 480px) {
  .elements_responsiveLinks__7-Nwu {
    visibility: hidden;
  }
}
@-webkit-keyframes topBarMenu_checked-anim__2736S {

    /* 50% {
        width: 3000px;
        height: 3000px;
    } */
    100% {
        width: 300px;
        height: 300px;
        border-bottom-right-radius: 90%;
    }
}

@keyframes topBarMenu_checked-anim__2736S {

    /* 50% {
        width: 3000px;
        height: 3000px;
    } */
    100% {
        width: 300px;
        height: 300px;
        border-bottom-right-radius: 90%;
    }
}

@-webkit-keyframes topBarMenu_not-checked-anim__3b9rp {
    0% {
        width: 300px;
        height: 300px;
    }
}

@keyframes topBarMenu_not-checked-anim__3b9rp {
    0% {
        width: 300px;
        height: 300px;
    }
}

.topBarMenu_linkItem__3mkE1 a {
    margin: 60px 0 -55px 0;
    color: #054186;
    font-weight: 700;
    line-height: 1.8;
    text-decoration: none;
    text-transform: none;
    list-style: none;
    outline: 0;
    font-size: 18px;
    /* display: none;   */
}

.topBarMenu_linkItem__3mkE1 li {
    margin: 60px 0 -55px 0;
    color: #054186;
    font-weight: 700;
    line-height: 1.8;
    text-decoration: none;
    text-transform: none;
    list-style: none;
    outline: 0;
    font-size: 18px;
    /* display: none;   */
}

/* .linkItem a:focus {
    display: block;
    color: #333;
    background-color: #eee;
    transition: all .5s;
} */

.topBarMenu_trigger__3kV3Q {
    position: absolute;
    top: 12px;
    left: 8px;
    /* background: #03A9F4; */
    width: 30px;
    height: 5px;
    -webkit-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer;
    z-index: 1600;
    height: 25px;
    background: none;
}

.topBarMenu_menuToggle__3jhj- {
    display: none;
}

.topBarMenu_menuToggle__3jhj-:checked+.topBarMenu_trigger__3kV3Q {
    top: 35px;
}

.topBarMenu_menuToggle__3jhj-:checked+.topBarMenu_trigger__3kV3Q {
    width: 20px;
    top: 2px;
    left: 18px;
}

.topBarMenu_menu__3Jleg {
    z-index: 1500;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 66px;
    height: 66px;
    background-color: #fff;
    border-bottom-right-radius: 85%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

.topBarMenu_burgerContainer__1q01W {
    display: none;
}

.topBarMenu_menuToggle__3jhj-:checked+.topBarMenu_trigger__3kV3Q+.topBarMenu_menu__3Jleg {
    -webkit-animation: topBarMenu_checked-anim__2736S .2s ease both;
            animation: topBarMenu_checked-anim__2736S .2s ease both;
}

.topBarMenu_menuToggle__3jhj-:checked+.topBarMenu_trigger__3kV3Q+.topBarMenu_menu__3Jleg>.topBarMenu_burgerContainer__1q01W {
    display: block;
}

.topBarMenu_beta__13piz {
    position: absolute;
    top: 18px;
    left: 32px;
    font-size: 12px;
    font-weight: 700;
    /* color: #B71C1C; */
    color: #000000;
    opacity: 0.8;
}
.helpIcon_helpicon__14VNF {
  width: 150px;
  /* height: 40px; */
  box-shadow: 0 0 5px #888;
  opacity: 0.95 !important;
}
.sectionHeader_title__1oNhN {
  font-size: 22px;
  /* background-color: #054186; */
  color: #ffffff;
  margin-bottom: 2px;
  padding-left: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;
  border-radius: 4px 4px 0px 0px;
}

.sectionHeader_drago__1azEn {
  background: -webkit-linear-gradient(315deg,rgb(5, 65, 134),rgb(1, 17, 36));
  background: linear-gradient(135deg,rgb(5, 65, 134),rgb(1, 17, 36));
}

.sectionHeader_vault__23iKv {
  background: -webkit-linear-gradient(315deg,rgb(96, 125, 139),rgb(40, 41, 41));
  background: linear-gradient(135deg,rgb(96, 125, 139),rgb(40, 41, 41));
}

.sectionHeader_container__3scrs {
  margin-bottom: 15px;
  position: relative;
  margin-right: -8px;
  margin-left: -8px;
}

.sectionHeader_actionButtonContainer__29PtQ {
  position: absolute;
  right: 0;
  top: -10px;
}

.sectionHeader_help__IVeyI {
  position: absolute;
  right: 5px;
  top: 5px;
}
.elementNotConnected_logoImg__1gx-o {}

.elementNotConnected_warningText__3PIIK {
  text-align: center;
  vertical-align: middle;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  color: #000000;
}

.elementNotConnected_paperContainer__3Ux6s {
  margin-top: 10px;
  display: 'inline-block';
}

.elementNotConnected_detailsBoxContainer__3UimH {
  /* padding-top: 5px;
  padding-bottom: 5px; */

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  bottom: 35px;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  padding-left: 8px;
  border-radius: 5px 5px 4px 4px;
  padding-right: 8px;
  right: 5px;
  height: 256px;
  animation: elementNotConnected_fadein__2_ftu 0.5s;
  -moz-animation: elementNotConnected_fadein__2_ftu 0.5s;
  /* Firefox */
  -webkit-animation: elementNotConnected_fadein__2_ftu 0.5s;
  /* Safari and Chrome */
  -o-animation: elementNotConnected_fadein__2_ftu 0.5s;
  /* Opera */
}

@keyframes elementNotConnected_fadein__2_ftu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes elementNotConnected_fadein__2_ftu {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.elementNotConnected_detailsSectionContainer__1LBn8 {
  padding-bottom: 5px;
}

.elementNotConnected_sectionParagraph__SmQb9 {
  padding-bottom: 5px;
}

.elementNotConnected_titleRBModal__EFfUf {
  /* float: left; */
  padding: 0 !important;
}

.elementNotConnected_titleRBHeader__jOYvW img {
  /* float: left; */
  width: 100%;
}

.elementNotConnected_titleRBHeaderContainer__2rTDL {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.elementNotConnected_titlePrimaryText__rCMw5 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  margin-left: auto;
}

.elementNotConnected_titleSecondaryText__3kmsS {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: auto;
}


.elementNotConnected_modalHeader__3UOT7 {
  padding: 0 !important;
  margin: -8px !important;
  border-radius: 2px;
  font-size: 16px !important;
  line-height: 20px !important;
}

.elementNotConnected_modalHeader__3UOT7 img {
  height: 90px;
  /* width: 460px; */
  padding-top: 22px;
  padding-bottom: 12px;
}
/* Copyright 2016 Rigo Investment Sagl. */

/* .body {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

} */

.application_headline__Q9kSs {
  font-size: 24;
  padding-top: 16;
  margin-bottom: 12;
  font-weight: 400;
}

.application_tabs__3sl9F {
  width: 100%;
  float: 'left';
}

.application_tab__3KVYJ {}

.application_copyright__3BB1C {
  font-size: 0.9em;
}

.application_bottombar__1gwXD {
  /* position: fixed;
  width: 100%;
  bottom: 0; */
  align-self: flex-end !important;
  /* flex: none !important; */
}

.application_maincontainer__2X7nm {
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.application_content__1zKrq {
  flex: 1 0 auto !important;
  height: 100%;
  margin-top: 55px;
}

.application_contentHomePages__2XkB8 {
  flex: 1 0 auto !important;
  height: 100%;
  margin-top: 18px;
}

.application_comingSoonBlur__26yn5 {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* ie8 */
  padding-left: 5px;
  /* padding-right: 5px; */
}

.application_fix__2uEQg {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.App_appContainer__C4Ogh {
  height: 100% !important;
}
/* Copyright 2016 Rigo Investment Sagl. */

html,
#src_header__1yI0T {
  margin: 0 !important;
  padding: 0 !important;
}


body {
  background: #fff;
  border: 0;
  color: #333;
  font-size: 16px;
  font-family: 'Muli', serif;
  margin-top: 0px;
  margin-bottom: 0;
  vertical-align: top;

}

#src_root__3RoIn {
  height: 100% !important;
}

html,
body,
#src_app__35rlJ,
#src_app__35rlJ>div {
  height: 100%
}
