.topBarButton {
  color: white !important;
  line-height: 32px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin-top: 5px !important;
  padding-top: 4px !important;
}

.topBarButtonSelected {
  color: white !important;
  line-height: 32px !important;
  border-bottom-color: #FFFFFF !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin-top: 5px !important;
  padding-top: 4px !important;
}