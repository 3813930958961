/* Copyright 2016 Rigo Investment Sagl. */
@import url('https://fonts.googleapis.com/css?family=Muli');

html,
#header {
  margin: 0 !important;
  padding: 0 !important;
}


body {
  background: #fff;
  border: 0;
  color: #333;
  font-size: 16px;
  font-family: 'Muli', serif;
  margin-top: 0px;
  margin-bottom: 0;
  vertical-align: top;

}

#root {
  height: 100% !important;
}

html,
body,
#app,
#app>div {
  height: 100%
}